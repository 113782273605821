import Headroom from "headroom.js";
import SmoothScroll from "smooth-scroll";
import fslightbox from "fslightbox";
import vhCheck from "vh-check";
// import Toast from "bootstrap/js/src/toast";

window.Swal = require('sweetalert2');

const viewportHeight = vhCheck({
    cssVarName: "vh-offset",
});

const scroll = new SmoothScroll('a[href*="#"]', {
    speed: 1000,
    speedAsDuration: true,
    updateURL: false,
    popstate: false,
    topOnEmptyHash: true,
});

const header = document.getElementById("site-header");
const headroom = new Headroom(header, {
    offset: header.offsetHeight / 2,
});
headroom.init();

//BURGER MENU
const headerMenu = document.getElementById("js-headerMenu");
const headerOpen = document.getElementById("js-headerOpen");
const headerClose = document.getElementById("js-headerClose");
const headerOverlay = document.getElementById("js-headerOverlay");

headerOpen.addEventListener("click", () => {
    toggleHeaderMenu();
    headroom.freeze();
});

headerOverlay.addEventListener("click", () => {
    toggleHeaderMenu();
    headroom.unfreeze();
});

headerClose.addEventListener("click", () => {
    toggleHeaderMenu();
    headroom.unfreeze();
});

function toggleHeaderMenu() {
    headerMenu.classList.toggle("header-menu--is-open");
    headerOverlay.classList.toggle("header-overlay--is-active");
}

//Scroll up arrow
const scrollUp = document.getElementById("js-scrollUp");
if (scrollUp) {
    scrollUp.addEventListener("click", function () {
        scroll.animateScroll(0);
    });
}

function scrollUpToggle() {
    if (window.pageYOffset > 100) {
        if (window.oldScroll > window.scrollY) {
            scrollUp.classList.add("active");
        } else {
            scrollUp.classList.remove("active");
        }
    } else {
        scrollUp.classList.remove("active");
    }
    window.oldScroll = window.scrollY;
}

window.onscroll = function () {
    scrollUpToggle();
};

document.querySelectorAll(".js-contact-form").forEach((element) => {
    element.addEventListener("submit", function (e) {
        e.preventDefault();
        submitContactForm(e);
    });
});

// Contact form send
function submitContactForm(element) {
    let url = element.target.dataset.url;
    let siteBody = document.querySelector("body");
    let formButton = document.querySelector(".form__button");
    formButton.disabled = true;

    // Append the form status
    let formStatus = document.createElement("div");
    formStatus.setAttribute("class", "form-status");

    let formData = {};
    let form = element.target;
    let data = new FormData(form);
    for (let [key, value] of data.entries()) {
        formData[key.toString()] = value;
    }

    fetch(url, {
        method: "POST",
        credentials: "same-origin",
        mode: "same-origin",
        headers: {
            "Cache-Control": "no-cache",
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": document
                .getElementsByName("csrf-token")[0]
                .getAttribute("content"),
        },
        body: JSON.stringify(formData),
    })
        .then((response) => response.json())
        .then((data) => {
            siteBody.appendChild(formStatus);
            if (data.status === 1) {
                formStatus.className += " alert-success";
                formStatus.innerHTML = data.msg ? data.msg : "?";
                form.reset();
                formButton.disabled = false;
            } else {
                let obj = data.msg;
                let message = "";
                if (typeof obj === "object") {
                    Object.keys(obj).map(function (key) {
                        message += obj[key] + "\n";
                    });
                } else {
                    message = obj;
                }
                formStatus.className += " alert-danger";
                formStatus.innerHTML = message ? message : "?";
            }

            setTimeout(function () {
                formStatus.remove();
            }, 3000);
        });
}


// import Swiper bundle with all modules installed
import Swiper from 'swiper/bundle';

// import styles bundle
import 'swiper/css/bundle';

import "./components/slide-down";

// init Swiper:
window.Swiper = Swiper;
